@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@700&display=swap');

.social-icons {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.social-icons a {
  margin: 0 10px;
  color: #fff; /* Set icon color */
  text-decoration: none; /* Remove default link underline */
}

.social-icons a:hover {
  color: #1336a8; /* Change icon color on hover */
}

.hero-content p {
  font-size: 1.5rem;
  color: #f0e7e7;
  width: 600px;
}

.footer-content p {
  position: absolute;
  bottom: 0;
  padding: 10px 0;
  text-align: center;
}

.hero-section {
    position: relative;
    height: 100vh;
  }
  
  .background-image {
    background-image: url('../assets/background-image.png');
    background-size: cover;
    background-position: center;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: filter 1s ease; /* Add transition effect */
  }
  
  .hero-content {
    text-align: center;
    z-index: 1;
    opacity: 0;
    transition: opacity 1s ease-in-out;
    font-family: 'Roboto Slab', serif; /* Apply block font */
  }
  
  .show-content .background-image {
    filter: brightness(0.1); /* Darken the background */
  }
  
  .show-content .hero-content {
    opacity: 1; /* Show the content */
  }
  
  @media screen and (max-width: 768px) {
    .hero-content h2 {
      font-size: 2rem;
    }
  
    .hero-content p {
      font-size: 1.2rem;
    }
  
    .social-icons img {
      width: 25px;
      height: 25px;
    }
  }