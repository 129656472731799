body {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    background-color: #000;
    color: #fff;
  }
  
  header {
    background-color: #0080ff;
    padding: 20px;
  }
  
  h1 {
    margin: 0;
    font-family: 'Orbitron', sans-serif;
  }
  
  .hero-section {
    background-image: url('./assets/background-image.png');
    background-size: cover;
    background-position: center;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  
  .hero-section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7));
  }
  
  .hero-content {
    text-align: center;
    z-index: 1;
  }
  
  .hero-content h2 {
    font-size: 3rem;
    color: #fff;
  }
  
  .hero-content p {
    font-size: 1.5rem;
    color: #ccc;
  }
  
  .social-icons {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
  
  .social-icons a {
    margin: 0 10px;
  }
  
  .social-icons img {
    width: 30px;
    height: 30px;
    transition: transform 0.3s ease;
  }
  
  .social-icons img:hover {
    transform: scale(1.2);
  }
  